import React, { Fragment } from "react";
import { Icon } from "react-icons-kit";
import { facebook } from "react-icons-kit/fa/facebook";
import { dribbble } from "react-icons-kit/fa/dribbble";
import { googlePlus } from "react-icons-kit/fa/googlePlus";
import { skype } from "react-icons-kit/fa/skype";
import { twitter } from "react-icons-kit/fa/twitter";
import Logo from "common/components/UIElements/Logo";
import Container from "common/components/UI/Container";
import flogo from "common/assets/image/interior/radiance-logo.png";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { FloatingWhatsApp } from 'react-floating-whatsapp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope  } from '@fortawesome/free-solid-svg-icons';

import FooterWrapper, { List, ListItem, SocialList } from "./footer.style";
import { marginBottom, textAlign } from "styled-system";

const Footer = () => {
  const socialLinks = [
    {
      id: 1,
      icon: <Icon icon={facebook} />,
      name: "facebook",
      link: "#",
    },
    {
      id: 2,
      icon: <Icon icon={dribbble} />,
      name: "dribbble",
      link: "#",
    },
    {
      id: 3,
      icon: <Icon icon={googlePlus} />,
      name: "googlePlus",
      link: "#",
    },
    {
      id: 4,
      icon: <Icon icon={skype} />,
      name: "skype",
      link: "#",
    },
    {
      id: 5,
      icon: <Icon icon={twitter} />,
      name: "twitter",
      link: "#",
    },
  ];

  return (
    <Fragment>
      <FooterWrapper>
        <Container width="1330px" className="footer_conttainerow">
          <div className="row">
            <div className="col-md-4" style={{ zIndex: "2" }}>
              <img
                className="footerImg"
                src="/home/home-image-18.webp"
                alt="Radinace Hair Wigs"
              /> 
 
            </div>
            <div className="col-md-4 mt-5">
              {/* <Logo
                className="logo"
                href="/"
                logoSrc={flogo}
                title="Radiance Hair Wigs in Delhi"
                style={{marginBottom: '20px'}}
              /> */}
  <Link to='/'><img src={flogo} width="170" height="44" alt="Radiance Hair Studio" style={{marginBottom: '20px'}} className="logoWrapper radianceMainLogo" /></Link>


              <p><a href="https://www.google.com/maps?cid=595399029095088499" target="_blank">3rd Floor, A-66, Rajouri Garden, New Delhi, Delhi 110027</a></p>

              <h3>Office Hours</h3>
              <p>
                Visiting Hours
                <br />
                11:00AM - 08:00PM <br /> Monday to Sunday
              </p>
              <p>
                Contact Hours
                <br />
                09:00AM - 09:00PM <br /> Monday to Sunday
              </p>
            </div>
            <div className="col-md-4 mt-5">
              <div className="row">
                <div className="col-md-6">
                  <h3>Quick Links</h3>
                  <ul>
                    <Link to='/about.php'><li>About Us</li></Link>
                    <Link to='/services.php'><li>Services</li></Link>
                    <Link to='/wigs-for-men.php'><li>Hair Wig Solutions</li></Link>
                    <Link to=''><li>Latest News</li></Link>
                    <Link to='/appointment.php'><li>Book Appointment</li></Link>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h3>Legal</h3>
                  <ul>
                    <li>Privacy Policy</li>
                    <li>Disclaimer Policy</li>
                   <Link to='/t&cRadiance.php'> <li>Terms of Service</li></Link>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="contactBox">
                  <h3>Contact Us</h3>
                  <p> <FontAwesomeIcon icon={faPhone } /> <a href="tel:+91-99585-85649" className="text-white"> +91-99585-85649</a></p>
                  <p> <FontAwesomeIcon icon={faEnvelope } /> <a href="mailto:contact@radiancehairstudio.com" className="text-white"> contact@radiancehairstudio.com</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <a
          href="https://api.whatsapp.com/send?phone=919958585649&text=Hey!%20I'm%20interested%20in%20Radiance%20Hair%20Studio%20services.%20Kindly%20guide%20me%20through%20it"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-btn"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
          </svg>
        </a>
      </FooterWrapper>
      <FooterWrapper className="footercpyr">
        <Container width="1330px" className="footer_conttainerow">
          <p>
            Copyright © 2025 Radiance Hair Studio. All Right Reserved. 
            {/* Designed
            by Immersive Infotech. */}
          </p>
        </Container>
      </FooterWrapper>
      {/* <FloatingWhatsApp
        phoneNumber="+91-99585-85649"
        accountName="Foo"
        allowEsc
        allowClickAway
        notification
        notificationSound
      /> */}
      
    </Fragment>
    
  );
};

export default Footer;
