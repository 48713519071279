import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import { DrawerProvider } from "common/contexts/DrawerContext";
import Navbar from "containers/Home/Navbar";
import Fade from "react-reveal/Fade";
import Seo from "components/seo";

// Section imports
import Heroarea from "containers/Home/Heroarea";
import BeforeAfter from "containers/Home/BeforeAfter";
import GoogleReviews from "containers/Home/GoogleReviews";
import AboutGrid from "containers/Home/AboutGrid";
import WhyRestoration from "containers/Home/WhyRestoration";
import HairPatch from "containers/Home/HairPatch";
import Features from "containers/Home/Feature";
import CallToAction from "containers/Home/CallToAction";
import WhyChoose from "containers/Home/WhyChoose";
import Counters from "containers/Home/Counters";
import SuccessStories from "containers/Home/SuccessStories";
import WatchVideos from "containers/Home/WatchVideos";
import Blogs from "containers/Home/Blogs";
import ContactDetails from "containers/Home/ContactDetails";
import Footer from "containers/Home/Footer";

// Global styles
import { ResetCSS } from "common/assets/css/style";
import "common/assets/css/main-page.css";
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from "containers/Home/global.style";

const Home = () => {
  // SEO metadata and structured data
  const seoData = {
    title: "Hair Wigs in Delhi | Men & Women Hair Patch - Radiance",
    description:
      "Discover top quality hair wigs in Delhi for both men and women at Radiance Hair Studio. Discover the perfect natural-looking hair patch to elevate your style.",
    keywords: [
      "hair wigs in Delhi",
      "hair wig for men",
      "hair wig for women",
      "hair wigs price",
      "wig shop near me",
      "Natural hair wig shop in Delhi",
    ],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    address: "3rd Floor, A-66, Rajouri Garden, New Delhi, Delhi 110027",
    additionalType: "https://www.radiancehairstudio.com/",
    alternateName: "Radiance Hair Studio",
    areaServed: "New Delhi, India",
    currenciesAccepted: "Rupees",
    description:
      "Are you looking for a human hair wig? Get it done from Radiance Hair Studio. Our team has 20 years of experience in hair solutions.",
    email: "contact@radiancehairstudio.com",
    knowsLanguage: "English",
    latitude: "0",
    legalName: "Radiance Hair Studio",
    location: "New Delhi",
    logo: "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    maps: "https://maps.app.goo.gl/sZEZdYgDafVfzZM76",
    name: "Radiance Hair Studio",
    openingHours: "11:00AM - 08:00PM",
    telephone: "+919958585649",
    image: [
      "https://www.radiancehairstudio.com/home/review.webp",
      "https://www.radiancehairstudio.com/home/human-hair-wigs.jpg",
      "https://www.radiancehairstudio.com/home/men-face1.jpg",
      "https://www.radiancehairstudio.com/home/women-face1.jpg",
      "https://www.radiancehairstudio.com/home/men-face.jpg",
      "https://www.radiancehairstudio.com/home/image%202.png",
      "https://www.radiancehairstudio.com/before-after/2.webp",
      "https://www.radiancehairstudio.com/before-after/11.webp",
      "https://www.radiancehairstudio.com/home/home-image-18.webp",
    ],
  };

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        {/* SEO Component */}
        <Seo
          {...seoData}
          schemaData={schemaData}
          canonical="https://www.radiancehairstudio.com"
        />
     
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Heroarea />
            <BeforeAfter />
            <GoogleReviews />
            <AboutGrid />
            <WhyRestoration />
<HairPatch />
            <Features />
            <CallToAction />
            <WhyChoose />
            <Counters />
            <SuccessStories />
            <div className="myContainer">
              <div className="row">
                <div className="col-md-4">
                  <Fade left>
                  <WatchVideos />
                  </Fade>
                </div>
                <div className="col-md-8">
                  <Fade right>
                  <Blogs />
                  </Fade>
                </div>
              </div>
            </div>
            <ContactDetails />
          </ContentWrapper>

          
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Home;
