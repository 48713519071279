import styled from "styled-components";

const SectionWrapper = styled.div`

margin-bottom: 60px;

.achivment{
p{
text-align:center;
}
}

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
    padding-bottom: 0;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 0;
    padding-bottom: 0;
    margin-bottom: 42px;

    .achivment div {
      border-right: 0px solid #dddddd;
    }.achivment div:last-child {
      border-bottom: 0;
      padding-bottom: 10px;
  }
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 70px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;




export default SectionWrapper;
