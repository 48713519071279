import React, { useState, useEffect } from "react";
import axios from "axios";
import SectionWrapper, { Container } from "./blogs.style";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 12;

  useEffect(() => {
    async function fetchAllPosts() {
      try {
        const baseUrl =
          "https://www.radiancehairstudio.com/blog/wp-json/wp/v2/posts";
        let allPosts = [];
        let currentPage = 1;
        let totalPages = 1;

        // Fetch the first page to get total pages
        const response = await axios.get(
          `${baseUrl}?per_page=100&page=${currentPage}`
        );
        allPosts = response.data;
        totalPages = parseInt(response.headers["x-wp-totalpages"], 10);

        // Fetch remaining pages
        while (currentPage < totalPages) {
          currentPage++;
          const nextPageResponse = await axios.get(
            `${baseUrl}?per_page=100&page=${currentPage}`
          );
          allPosts = [...allPosts, ...nextPageResponse.data];
        }

        setPosts(allPosts);
      } catch (error) {
        console.error("Error fetching all posts:", error);
      }
    }
    fetchAllPosts();
  }, []);

  // Calculate indices for the current page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = posts.slice(indexOfFirstBlog, indexOfLastBlog);

  // Pagination controls
  const totalPages = Math.ceil(posts.length / blogsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function stripHtmlTagsAndEntities(htmlString, wordLimit = 20) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    const textContent = tempElement.textContent || tempElement.innerText;
    const sanitizedContent = textContent.replace(/&hellip;/g, "...");
    const words = sanitizedContent.split(/\s+/);
    return words.slice(0, wordLimit).join(" ");
  }

  return (
    <SectionWrapper id="watchVideos">
      <div className="container mt-4">
        <h3 className="title results_title text-center mb-4">Featured Blogs</h3>
        <div className="row">
          {currentBlogs.map((post, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="blogCard border rounded shadow-sm">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={post.featured_image_url}
                  alt={post.title.rendered}
                />
                <div className="p-3 box-height">
                  <h3>
                    {post.title.rendered
                      .replace(/&#8217;s/g, "&")
                      .replace(/&#8211;/g, "–")}
                  </h3>
                  <p className="excerpt">
                    {stripHtmlTagsAndEntities(post.excerpt.rendered, 20)}
                  </p>
                  <a
                    target="_blank"
                    href={post.link}
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <nav>
            <ul className="custom-pagination justify-content-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`custom-page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="custom-page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </SectionWrapper>
  );
};

export default Blogs;
