import React from 'react';
import SectionWrapper, {
    Container
} from './hairPatch.style';

import Fade from 'react-reveal/Fade';

const HairPatch = () => {

    return (
        <SectionWrapper id="counters">
            <Container className='myContainer flexDirecColumn px-3'>
                <Fade bottom><h2 style={{ color: '#000', marginBottom: '20px', textAlign: 'center' }}>Hair Patch in Delhi for Mens and Women</h2></Fade>
                <Fade bottom>
                    <div className="achivment">
                        <p>Hair patches have become a popular and effective solution for people dealing with hair loss in Delhi. This non-surgical method offers an immediate transformation, providing a fuller, natural-looking head of hair without the need for invasive treatments like hair transplants. Hair patches are made from high-quality natural hair, which is customized to match the individual's hair texture, color, and density.
                        </p>
                        <p>Radiance Hair Clinic is one of the leading providers of premium hair patch in Delhi, known for delivering excellent quality and outstanding customer service. Many satisfied clients have experienced a boost in their confidence after opting for the clinic’s tailored hair patch solutions.
                        </p>
                    </div>
                </Fade>
            </Container>
        </SectionWrapper>
    );
};

export default HairPatch;
