import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  schemaData,
  canonical,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;

        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              meta={[
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
                {
                  name: `google-site-verification`,
                  content: `ycRuYlN4LBkFaLqiHDXa-3KtwiuSuWybQMvO7DfcPFk`,
                },
              ]
                .concat(
                  Array.isArray(keywords) && keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                      }
                    : []
                )
                .concat(meta)}
            >
              {canonical && <link rel="canonical" href={canonical} />}
              {schemaData && (
                <script type="application/ld+json">
                  {JSON.stringify(schemaData)}
                </script>
              )}
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-YQW7GQ1EYB"
              ></script>
              <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments);
                  }
                  gtag('js', new Date());
                  gtag('config', 'G-YQW7GQ1EYB');
                `}
              </script>
            </Helmet>
          </>
        );
      }}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  schemaData: null,
  canonical: null,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  schemaData: PropTypes.object,
  canonical: PropTypes.string,
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
